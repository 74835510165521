import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { Tooltip }  from 'react-tooltip';
import { toast } from 'react-toastify'; 

const Introduction = () => {
  const [copied, setCopied] = useState(false);
  const [buttonColor, setButtonColor] = useState('white'); 

  const textToCopy = `
  curl https://api.openai.com/v1/chat/completions \
  -H "Content-Type: application/json" \
  -H "Authorization: Bearer $OPENAI_API_KEY" \
  -d '{
     "model": "gpt-3.5-turbo",
     "messages": [{"role": "user", "content": "Say this is a test!"}],
     "temperature": 0.7
   }'
`;
const SecondtextToCopy = `
{
  "id": "chatcmpl-abc123",
  "object": "chat.completion",
  "created": 1677858242,
  "model": "gpt-3.5-turbo-1006",
  "usage": {
      "prompt_tokens": 13,
      "completion_tokens": 7,
      "total_tokens": 20
  },
  "choices": [
      {
          "message": {
              "role": "assistant",
              "content": "\n\nThis is a test!"
          },
          "finish_reason": "stop",
          "index": 0
      }
  ]
}
`;

  const containerStyle = {
     marginTop: '100px', // Adjust the top margin
    padding: '0 10px', // Adjust padding for smaller screens
  };

  const textBlockStyle = {
    fontSize: "17px",
    fontWeight: 300,
    color: "var(--text-color)",
    padding: "10px 60px",
    height:"auto"
  };

  const leftAlignStyle = {
    textAlign: "left",
  };
  const headingStyle = {
    fontSize: "25px",
    fontWeight: 600,
    padding: "10px 60px",
    textAlign: "left",
  };

  const Style = {
    width: '80%', // Set a percentage width for responsiveness
    maxWidth: '800px', // Add a maximum width to maintain readability
    borderRadius: '5px',
    padding: "10px 60px",
    backgroundColor: '#36454F',
    color: 'white',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
  
  const handleCopy = () => {
    setCopied(true);
    setButtonColor('green');
    toast.success('Text Copied');

    setTimeout(() => {
      setCopied(false);
      setButtonColor('white'); 
    }, 2000);
  };
  const buttonContainerStyle = {
    margin: '10px 0', // Add margin to separate the button from the text
    textAlign: 'center', // Center the button on smaller screens
  };
  const contentStyle = {
    width: '100%', // Make the content take full width
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:"center",
    textAlign:"center",
    padding: "10px 60px",
    
  };
  
  return (
    <div  style={containerStyle}>
      <h1 style={headingStyle } >Introduction</h1>
      <div style={{ ...textBlockStyle, ...leftAlignStyle }}>
      <p>
          Welcome to the Medical GPT Documentation. This project is dedicated to providing you with comprehensive information on medicines and related healthcare topics.
        </p>
        <p>
          Our mission is to offer valuable insights and knowledge on a wide range of medical subjects, from medications and treatments to health and well-being.
        </p>
        <p>
          Medical GPT is your trusted source for accurate and up-to-date information, making it easier for you to understand medicines, their uses, side effects, and more.
        </p>
        <p>
          If you have health concerns or are looking for guidance, Medical GPT can assist you in understanding your symptoms, recommending suitable medications, suggesting dietary choices, and providing information about various health conditions.
        </p>
      </div>

      <div className=' sm:p-0 xl:pt-0 border lg:w-[700px] md:w-[600px] w-200px sm:ml-5' style={{backgroundColor:"#36454F",color:"white", borderRadius: '5px', margin: '0 auto',display: 'flex',flexDirection: 'column'}}>
      <div style={buttonContainerStyle}>
              <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                <button data-tip data-for="copyTooltip" style={{ color: buttonColor }}>
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          <div style={contentStyle }>
            <p>{textToCopy}</p>
          </div>
        </div>

      <h1 style={ headingStyle }>Authentication</h1>
      <div style={{ ...textBlockStyle, ...leftAlignStyle }}>
      <p>
          To access the full capabilities of Medical GPT, please use the provided credentials:
        </p>
        <p>Username: exampleuser</p>
        <p>Password: ********</p>
      </div>
      <div className=' sm:p-0 xl:pt-0 border lg:w-[700px] md:w-[600px] w-200px sm:ml-5' style={{backgroundColor:"#36454F",color:"white", borderRadius: '5px', margin: '0 auto',display: 'flex',flexDirection: 'column'}}>
      <div style={buttonContainerStyle}>
              <CopyToClipboard text={SecondtextToCopy} onCopy={handleCopy}>
                <button data-tip data-for="copyTooltip" style={{ color: buttonColor }}>
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          <div style={contentStyle }>
            <p>{SecondtextToCopy}</p>
          </div>
        </div>
      <h1 style={headingStyle } >Organization</h1>
      <div style={{ ...textBlockStyle, ...leftAlignStyle }}>
      <p>
          Medical GPT is developed by Datalytics AI, dedicated to providing cutting-edge healthcare solutions.
        </p>
        <p>
          Our headquarters are located in New York, USA, where we continue to innovate and support healthcare professionals and individuals worldwide.
        </p>
      </div>
    </div>
  );
};

export default Introduction;
