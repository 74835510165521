import React, { useState, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Navbar from "./Navbar";
import Introduction from "./Introduction"; 
import { AiOutlineBars } from "react-icons/ai";
import { AiFillLock } from "react-icons/ai";
import { FaFileCode } from "react-icons/fa";
import Authenticate from "./Authenticate";

function Apidocs() {
  const [sidebarClosed, setSidebarClosed] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Introduction');

  const toggleSidebar = () => {
    setSidebarClosed(!sidebarClosed);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    // Check window width on mount and resize
    const checkWindowSize = () => {
      if (window.innerWidth <= 768) {
        setSidebarClosed(true);
      } else {
        setSidebarClosed(false);
      }
    };

    // Attach event listener for window resize
    window.addEventListener('resize', checkWindowSize);

    // Initial check on mount
    checkWindowSize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <div className={`App ${darkMode ? "dark" : ""}`} style={{ marginTop: "150px" }}>
        <nav style={{ marginTop: "92px" }} className={`border sidebar ${sidebarClosed ? "close" : ""}`}>
          <header>
            <div className="image-text">
              <span className="image">
                <FaFileCode />
              </span>
              <div className="text logo-text">
                <span className="name">Api Documentation</span>
              </div>
            </div>
            <i className={`bx bx-chevron-right toggle mt-1`} style={{color:"black" ,backgroundColor:"transparent", fontSize:"25px"}} onClick={toggleSidebar}>
              <AiOutlineBars />
            </i>
          </header>

          <div className="menu-bar">
            <div className="menu">
              <ul className="menu-links">
                <li className="nav-link">
                  <a href="#" onClick={() => handleTabChange('Introduction')}>
                    <FaInfoCircle className={`bx bx-home-alt icon ${selectedTab === 'Introduction' ? 'active' : ''}`} />
                    <span className={`text nav-text ${selectedTab === 'Introduction' ? 'active' : ''}`}>Introduction</span>
                  </a>
                </li>
                <li className="nav-link">
                  <a href="#" onClick={() => handleTabChange('Authentication')}>
                    <AiFillLock className={`class='bx bx-bar-chart-alt-2 icon ${selectedTab === 'Authentication' ? 'active' : ''}`} />
                    <span className={`text nav-text ${selectedTab === 'Authentication' ? 'active' : ''}`}>Authentication</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <section className="container home" style={{ height: "auto" }}>
          {selectedTab === 'Introduction' && <Introduction />}
          {selectedTab === 'Authentication' && <Authenticate />}
        </section>
      </div>
    </div>
  );
}

export default Apidocs;
