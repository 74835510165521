import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { Tooltip }  from 'react-tooltip';
import { toast } from 'react-toastify'; 

const Authenticate = () => {
  const [copied, setCopied] = useState(false);
  const [buttonColor, setButtonColor] = useState('white'); 

  const textToCopy = `
  Authorization: Bearer OPENAI_API_KEY
`;
const SecondtextToCopy = `
curl https://api.openai.com/v1/models \
  -H "Authorization: Bearer $OPENAI_API_KEY" \
  -H "OpenAI-Organization: YOUR_ORG_ID"
`;
  const containerStyle = {
    marginTop: "100px",
  };

  const textBlockStyle = {
    fontSize: "17px",
    fontWeight: 300,
    color: "var(--text-color)",
    padding: "10px 60px",
  };

  const leftAlignStyle = {
    textAlign: "left",
  };
  const headingStyle = {
    fontSize: "25px",
    fontWeight: 600,
    padding: "10px 60px",
    textAlign: "left",
  };

  const Style = {
    width: '800px',
    borderRadius: '5px',
    padding: '20px 60px',
    backgroundColor: '#36454F',
    color: 'white',
    margin: '0 auto', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center', 
  };
  
  const handleCopy = () => {
    setCopied(true);
    setButtonColor('green');
    toast.success('Text Copied');

    setTimeout(() => {
      setCopied(false);
      setButtonColor('white'); 
    }, 2000);
  };
  const buttonContainerStyle = {
    margin: '10px 0', // Add margin to separate the button from the text
    textAlign: 'center', // Center the button on smaller screens
  };
  const contentStyle = {
    width: '100%', // Make the content take full width
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:"center",
    textAlign:"center",
    padding: "10px 60px",
    
  };
  
  return (
    <div className='container' style={containerStyle}>
      <h1 style={headingStyle } >Authentication</h1>
      <div style={{ ...textBlockStyle, ...leftAlignStyle }}>
      <p>
      The OpenAI API uses API keys for authentication. Visit your API Keys page to retrieve the API key you'll use in your requests.
        </p>
        <p>
        Remember that your API key is a secret! Do not share it with others or expose it in any client-side code (browsers, apps). Production requests must be routed through your own backend server where your API key can be securely loaded from an environment variable or key management service.
        </p>
        <p>
        All API requests should include your API key in an Authorization HTTP header as follows:
        </p>
      
      </div>
      <div>
      <div className=' sm:p-0 xl:pt-0 border lg:w-[700px] md:w-[600px] w-200px sm:ml-5' style={{backgroundColor:"#36454F",color:"white", borderRadius: '5px', margin: '0 auto',display: 'flex',flexDirection: 'column'}}>
      <div style={buttonContainerStyle}>
              <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                <button data-tip data-for="copyTooltip" style={{ color: buttonColor }}>
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          <div style={contentStyle }>
            <p>{textToCopy}</p>
          </div>
        </div>
    </div>
      <h1 style={ headingStyle }>Organization (optional)</h1>
      <div style={{ ...textBlockStyle, ...leftAlignStyle }}>
      <p>
      For users who belong to multiple organizations, you can pass a header to specify which organization is used for an API request. Usage from these API requests will count as usage for the specified organization.
        </p>
        <p>Example curl command:

</p>
      </div>
      <div className=' sm:p-0 xl:pt-0 border lg:w-[700px] md:w-[600px] w-200px sm:ml-5' style={{backgroundColor:"#36454F",color:"white", borderRadius: '5px', margin: '0 auto',display: 'flex',flexDirection: 'column'}}>
      <div style={buttonContainerStyle}>
              <CopyToClipboard text={SecondtextToCopy} onCopy={handleCopy}>
                <button data-tip data-for="copyTooltip" style={{ color: buttonColor }}>
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          <div style={contentStyle }>
            <p>{SecondtextToCopy}</p>
          </div>
        </div>
      <h1 style={headingStyle } >Making requests</h1>
      <div style={{ ...textBlockStyle, ...leftAlignStyle }}>
      <p>
      You can paste the command below into your terminal to run your first API request. Make sure to replace $OPENAI_API_KEY with your secret API key.
        </p>
      </div>
    </div>
  );
};

export default Authenticate;
