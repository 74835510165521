import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './Navbar';
import Result from './Result';
import InputBox from './InputBox';
import { Menu } from '@headlessui/react';

export default function Main() {
  const [submittedQuestion, setSubmittedQuestion] = useState('');
  const { t, i18n } = useTranslation();


  const handleQuestionSubmit = (question) => {
    setSubmittedQuestion(question);
  };

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    changeLanguage(language);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleReset = () => {
    setSubmittedQuestion('');
  };

  return (
    <div className="flex flex-col min-h-screen pt-28">
      <Navbar />


{/* Change Language DropDown */}
      <div className="flex justify-end mt-4 pr-8">
   
    </div>

      <div className="flex-grow">
        <div className="flex justify-center text-4xl font-bold text-indigo-800">
Medical <span className='text-red-500'> GPT</span>
        </div>
        <div className="lg:text-2xl text-red-500 text-xl md:w-[350px] lg:w-[700px]  md:p-6 font-semibold m-auto w-[300px] pt-2">
        {t('main.subtitle')}
        </div>

        <div>
          {/* Pass the handleQuestionSubmit function as a prop to InputBox */}
          {/* <InputBox onSubmitQuestion={handleQuestionSubmit} /> */}
   
        </div>
        <div className="flex justify-center mt-12 gap-8 ">
          {/* Pass the submittedQuestion and handleReset function as props to Result */}
          <Result question={submittedQuestion} onReset={handleReset} />
        </div>
      </div>

      <footer className="bg-white rounded-lg shadow m-4 mt-24 dark:bg-gray-800">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-indigo-500 sm:text-center dark:text-gray-400">
          {t('footer.title')}
            <a href="https://datalyticsai.com" className="hover:underline">
              <b> {t('footer.subtitle')}</b>
            </a>
            {t('email')}
          </span>
        </div>
      </footer>
    </div>
  );
}
