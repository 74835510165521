import logo from './logo.svg';
import './App.css';
import Main from './Components/Main';
import Apidocs from './Components/Apidocs';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className=' '>
     
        <Routes>
          <Route path="/apidocs" element={<Apidocs/>} />
          <Route path="/" element={<Main/>} />
          </Routes>
     
    </div>
  );
}

export default App;
