import React, { useEffect, useState } from "react";
import styles from "../answer.module.css";
import {HiSpeakerWave } from 'react-icons/hi2';
function Answer({ text }) {
  const [words, setWords] = useState([]);
  const [speaking, setSpeaking] = useState(false);
  const convertLineBreaks = true;

  useEffect(() => {
    let formattedText = text;
    if (convertLineBreaks) {
      formattedText = text.replace(/<br>/g, "\n");
    }
    setWords(formattedText.split(" "));
  }, [text]);

  const handleSpeak = () => {
    if (speaking) {
      // If already speaking, stop the speech
      window.speechSynthesis.cancel();
      setSpeaking(false);
    } else {
      // If not speaking, start speaking
      const speech = new SpeechSynthesisUtterance(text);
      setSpeaking(true);
  
      speech.onend = () => {
        setSpeaking(false);
      };
  
      window.speechSynthesis.speak(speech);
    }
  };
  

  return (
    <div className="flex justify-around gap-12">
      <div>
        {words.map((word, index) => (
          <span
            key={index}
            className={styles.fadeIn}
            style={{ animationDelay: `${index * 0.001}s` }}
          >
            {word}{" "}
          </span>
        ))}
      </div>
      <div>   <button onClick={handleSpeak}>
      <HiSpeakerWave style={{fontSize:"50px" , color:"red"}}/>
      <p className="text-red-700"> {speaking ? "Listening..." : "Listen"}</p>
   
      </button></div>
   
    </div>
  );
}

export default Answer;
