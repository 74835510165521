import React, { useState, useEffect } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FaMicrophone } from 'react-icons/fa';
import './InputBox.css'; 
import { useTranslation } from 'react-i18next';

export default function InputBox({ onSubmitQuestion , result  }) {
  const [question, setQuestion] = useState('');
  const [recording, setRecording] = useState(false); // State to track recording status
  const { t } = useTranslation();
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    startListening,
    stopListening
  } = useSpeechRecognition();

  useEffect(() => {
    if (transcript !== '') {
      setQuestion(transcript);
    }
  }, [transcript]);

  const handleInputChange = (event) => {
    setQuestion(event.target.value);
  };
  
  // Add a separate function to handle keyboard input submission
  const handleInputSubmit = () => {
    if (!question.trim()) {
      alert('Please enter a question before submitting.');
      return;
    }
  
    onSubmitQuestion(question);
    console.log('Here is the question: ', question);
  };
  
  

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!question.trim()) {
      alert('Please enter a question before submitting.');
      return;
    }

    onSubmitQuestion(question);
    console.log('Here is the question: ', question);
  };

  const handleToggleListening = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }
  
    // Toggle the recording state when the microphone is clicked
    setRecording(!recording);
  };
  
  useEffect(() => {
    if (!listening && question.trim() !== '') {
      // Call the API when recording has stopped and there is a question
      onSubmitQuestion(question);
    }
  }, [listening]);
  
  useEffect(() => {
    if (transcript !== '') {
      setQuestion(transcript);
    }
  }, [transcript]);
  
  
  

  return (
    <div>
      <div className="lg:w-[900px] md:w-[600px] w-[300px] ">
        <form className="border-2 rounded shadow-md border-red-500" onSubmit={handleSubmit}>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-red-300 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {/* ... (search icon SVG) */}
            </div>
            <input
              className="block lg:w-[800px] md:w-[500px] w-200px p-4 pl-10 pr-20 text-sm text-black rounded-lg bg-white dark:text-white outline-none" style={{color:"black" , backgroundColor:"white"}}
              placeholder={t('placholder')}
              required
              value={question}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              className=" text-red-500 absolute right-5 bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl  py-2"
            >
              <AiOutlineSend />
            </button>
            <div >
            <button
              type="button"
              className={`text-red-500 absolute left-38 top-2 font-large rounded-lg text-xl px-2 py-2 ${
                recording ? 'recording' : ''
              }`}
              onClick={handleToggleListening}
            >
              <FaMicrophone style={{ fontSize: '20px' }} />
              {/* <p className={`text-red-500 ${recording ? 'hidden' : ''}`}>
              {t('search.voice')}
              </p> */}
            </button>
            </div>
           
          </div>
        </form>
        <div className={` w-48 mt-3 rounded-lg flex justify-center m-auto ${recording ? 'bg-blue-200' : ''}`}>
        {(!result) && (
            <div className={`w-48 mt-3 rounded-lg flex flex-col justify-center items-center m-auto`}>
              {/* <button
                type="button"
                className={`text-red-500 font-large rounded-lg text-xl px-4 py-2 ${recording ? 'recording' : ''}`}
                onClick={handleToggleListening}
              >
                <FaMicrophone style={{ fontSize: "80px" }} />
              </button> */}
              {/* <p className={`text-red-500 ${recording ? 'hidden' : ''}`}>
              {t('search.voice')}
              </p> */}
            </div>
          )}
            </div>
      </div>
    </div>
  );
}
