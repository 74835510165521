import React, { useState, useEffect } from 'react';
import Answer from './Answer';
import InputBox from './InputBox';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from "axios";
import { SlReload } from 'react-icons/sl';
import { useTranslation } from 'react-i18next';

export default function Result({ onReset }) {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [received, setReceived] = useState(false);
    const { t } = useTranslation();

  const handleQuestionSubmit = (question) => {
    setQuery(question);
  };

  useEffect(() => {
    if (query) {
      setLoading(true);
      setAnswer("");
      setReceived(false);
      const fetchData = async () => {
        try {
          const { data: ip } = await axios.get('https://api.ipify.org?format=json');
          const request = await fetch('https://backend.medicalgpt.info/data', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ question: query, ip }),
          });
          if (request.ok) {
            const response = await request.json();
            setData(response.data);
            setAnswer(response.answer);
            setReceived(true);
          } else {
            setLoading(false);
            console.error('Error fetching data:', request.statusText);
            throw new Error(request.statusText);
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [query]);

  const handleReset = () => {
    setQuery("");
    setData([]);
    setAnswer("");
    setLoading(false);
    setReceived(false);
    onReset(); // Call the onReset function from the parent component if necessary
  };

  return (
    <div className="lg:w-[950px] md:w-[600px] sm:w-[500px]  sm:p-0 xl:pt-0 response-main">
   <div className="flex  ">
        <div>
          <InputBox onSubmitQuestion={handleQuestionSubmit}  result={data.length > 0 || answer.length > 0}  />
        </div>
        <div className='flex items-center justify-center text-red-500 pl-1 ' style={{marginTop:"-20px"}}>
          <SlReload onClick={handleReset} style={{ fontSize: "30px",   cursor: "pointer" }}
             className="lg:w-[30px] md:w-[30px] sm:w-[25px] w-full h-auto"
             />
        </div>
      </div>

      {loading && !received ? (
        <div className="lg:w-[900px] md:w-[600px] w-[300px] ">
          <div className="font-bold text-indigo-800 text-2xl mt-6 ">   {t('waiting')}</div>
          <div className="animate-pulse mt-2">
            <div className="h-4 bg-red-300 rounded"></div>
            <div className="h-4 bg-red-300 rounded mt-2"></div>
            <div className="h-4 bg-red-300 rounded mt-2"></div>
            <div className="h-4 bg-red-300 rounded mt-2"></div>
            <div className="h-4 bg-red-300 rounded mt-2"></div>
            <div className="h-4 bg-red-300 rounded mt-2"></div>
            <div className="h-4 bg-red-300 rounded mt-2"></div>
            <div className="h-4 bg-red-300 rounded mt-2"></div>
          </div>
        </div>
      ) : (
          <div className="sm:w-[80%] xl:w-[50%] lg:w-[50%] sm:p-0 xl:pt-0 response-main">
            <div className="response-section">
              {answer.length > 0 && (
                <div className="font-bold text-2xl text-indigo-800  ">Answer</div>
              )}
              <div className="answer-container ">
                {answer.length === 0 ? (
                  console.log('No answer')
                ) : (
                  <div className='lg:w-[800px] md:w-[600px] sm:w-[500px]'>
                          <Answer text={answer} />
                        </div>
                  
                  )}
              </div>
            </div>
            <div className="response-section lg:w-[900px] md:w-[700px] sm:w-[500px]">
              {data.length > 0 && (
                <div className="font-bold text-2xl text-indigo-800">Related Research Papers</div>
              )}
              {data.length === 0 ? (
                console.log('No data')
              ) : (
                  data.map((item, i) => (
                    <div key={i} className="response-container flex justify-start">
                      <div className="flex-grow">
                        <h2 className="text-blue-500 text-xl">
                          <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                        </h2>
                        <p className="text-sm mt-3">{item.journal}</p>
                      </div>
                      <button className="text-gray-400 hover:text-gray-500">
                        <a href={item.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-eye"></i></a>
                      </button>
                    </div>
                  ))
                )}
            </div>

          </div>
        )}
    </div>
  );
}
